<template>
  <div>
    <DetailItemEditCard item-name="team" :promises.sync="detailItemEditCardPromises" title="Project toevoegen"
    :detailViewRouteLocation="{ name: RouteNames.TEAM_DETAIL, params: { teamId: teamId }}" confirmationMessage="Project succesvol toegevoegd"
    update-store-action="teamsModule/putNewProjectsInTeam" :update-store-action-payload="{teamId: teamId, newProjects: getFormResultSet}"
    :success-callback="projectsAddedSuccessCallback">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete required
            v-model="selectedClientId"
            :items="getSelectClients"
            :rules="getRequiredRules('Klant moet ingevuld worden')"
            label="Klant">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete multiple required :disabled="disableProjectCombobox"
            v-model="selectedProjects"
            :items="getProjectsFromSelectedClient"
            :rules="getRequiredRules('Project moet ingevuld worden')"
            label="Project">
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleAll" @mousedown.prevent>
                <v-list-item-action>
                  <v-icon>{{ allIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Selecteer alle</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"/>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </DetailItemEditCard>
  </div>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import RouteNames from "@/router/RouteNames";
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';

export default {
  name: "AddTeamProject",
  components: {
    DetailItemEditCard,
  },
  props: {
    teamId: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      RouteNames,
      newProjects: [],
      clients: [],
      team: {
        teamClients: []
      },
      selectedClientId: null,
      selectedProjects: null,
      detailItemEditCardPromises: [
        this.$store.dispatch("clientsModule/fetchNotArchivedClients")
          .then(result => {
            this.clients = result
          }),
        this.$store.dispatch("teamsModule/fetchTeam", this.teamId)
          .then(result => {
            this.team = {
              ...result,
              teamClients: result?.teamClients || []
            }
          })
      ],
    }
  },
  methods: {
    getRequiredRules,
    projectsAddedSuccessCallback() {
      this.$router.push({ name: RouteNames.TEAM_DETAIL, params: { teamId: this.teamId } })
    },
    toggleAll() {
      this.$nextTick(() => {
        if(this.hasChosenAllProjects) {
          this.selectedProjects = []
        } else {
          this.selectedProjects = this.getProjectsFromSelectedClient.map(project => project.value)
        }
      })
    }
  },
  computed: {
    getSelectClients() {
      // Make a list of clients that already have all their projects added
      const fullyAddedClients = this.clients.filter(client => 
        !this.team.teamClients.some(teamClient => 
        client.id === teamClient.clientId && 
        teamClient.teamProjects.length === client.projects.filter(project => project.active).length
      ))

      // Apply filters and return as a useable array for the v-autocomplete
      return this.clients
        .filter((client) => client.active && fullyAddedClients.includes(client))
        .map((client) => { return { text: client.clientName, value: client.id } })
    },
    disableProjectCombobox() {
      return !this.selectedClientId
    },
    getProjectsFromSelectedClient() {
      if (this.disableProjectCombobox) {
        return null
      } else {
        // Make a list of project id's that are already added to this team
        const alreadyAddedProjects = this.team.teamClients
          .flatMap((teamClient) => teamClient.teamProjects)
          .map((teamProject) => teamProject.projectId)

        // Apply filters and return as a useable array for the v-autocomplete
        return this.clients.filter((client) => client.id === this.selectedClientId)[0]
          .projects
          .filter((project) => project.active)
          .filter((project) => !alreadyAddedProjects.includes(project.id))
          .map((project) => { return { text: project.projectName, value: project.id } })
      }
    },
    getFormResultSet() {
      // Return a set of results that the backend expects
      if(this.selectedProjects) {
        return this.selectedProjects.map((project) => { return { clientId: this.selectedClientId, projectId: project}})
      }
      return null
    },
    hasChosenAllProjects() {
      return this.selectedProjects && this.selectedProjects.length === this.getProjectsFromSelectedClient.length
    },
    hasChosenProjects() {
      return this.selectedProjects && this.selectedProjects.length > 0 && !this.hasChosenAllProjects
    },
    allIcon() {
      if (this.hasChosenAllProjects) return 'mdi-close-box'
      if (this.hasChosenProjects) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    selectedClientId() {
      this.selectedProjects = null
    },
  }
}
</script>